'use strict'

const wSpySettings = {
    includeLogs: 'lifeCycle,createInstance,runMethod,createInstance,readyManager,RMI',
    moreLogs: 'get',
    MAX_LOG_SIZE: 500,
    GROUP_MIN_LEN: 5,
    stackFilter: /wSpy/i
}

// If you add a script path here, you must ensure that it has the https protocol (https://jira.wixpress.com/browse/WEED-9770)
module.exports = {
    DATA_BINDING_APP_DEF_ID: 'dataBinding',
    WIX_CODE_APP_DEF_ID: '675bbcef-18d8-41f5-800e-131ec9e08762',
    LODASH: 'https://static.parastorage.com/unpkg/lodash@4.17.11/lodash.min.js',
    PM_RPC: 'https://static.parastorage.com/unpkg/pm-rpc@1.0.11/build/pm-rpc.min.js',
    WORKER_SENTRY_DSN: 'https://ad32b9de91af4e90b5bec12c758a1c1e@sentry.io/305752',
    WSPY_LATEST_DSN: 'https://static.parastorage.com/unpkg/wspy@3.0.19/dist/package/wspy-all-debug.js',
    wSpySettings
}
